/* istanbul ignore file */
// @todo tymofiih@wix.com research enzyme render issue

import React from 'react';
import {Button} from 'wix-ui-tpa/Button';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import * as productPageButtonsContainerStyles from '../ProductPageButtonsContainer.scss';
import {classes as addToCartButtonClasses} from '../AddToCartButton/AddToCartButton.st.css';
import {classes as buyNowButtonClasses} from '../BuyNowButton/BuyNowButton.st.css';
import classNames from 'classnames';

interface BackInStockButtonInterface extends ProvidedGlobalProps, IProvidedTranslationProps {
  className?: string;
}

interface BackInStockButtonState {
  showError: boolean;
}

export enum DataHook {
  Root = 'BackInStockButton.Root',
  ErrorMessage = 'BackInStock.ErrorMessage',
  CTAButton = 'BackInStock.Button',
}

@withGlobalProps
@withTranslations('globals.texts')
export class BackInStockButton extends React.Component<BackInStockButtonInterface, BackInStockButtonState> {
  private createNotification() {
    this.props.globals.handleNotifyWhenBackInStock();
  }

  private get showError() {
    return this.props.globals.showBackInStockError;
  }

  private getClasses() {
    const {shouldShowWishlistButton, shouldShowAddToCartButton, shouldShowBuyNowButton} = this.props.globals;
    return classNames({
      [addToCartButtonClasses.addToCartButton]: shouldShowAddToCartButton,
      [buyNowButtonClasses.buyNowButton]: !shouldShowAddToCartButton && shouldShowBuyNowButton,
      [productPageButtonsContainerStyles.primaryButton]: shouldShowWishlistButton,
      [this.props.className]: true,
    });
  }

  private getButtonText() {
    return this.props.t('productPage.backInStock.notifyWhenAvailable.button');
  }

  public render() {
    return (
      <div data-hook={DataHook.Root}>
        <Button
          onClick={() => {
            this.createNotification();
          }}
          className={this.getClasses()}
          data-hook={DataHook.CTAButton}>
          {this.getButtonText()}
        </Button>
        {this.showError && (
          <div data-hook={DataHook.ErrorMessage}>
            {this.props.t('productPage.sr.backInStock.note.selectAllOptions.text')}
          </div>
        )}
      </div>
    );
  }
}
